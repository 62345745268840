import React from "react";
import { Link } from "react-router-dom";
// import Image from "../../components/image";
import i18n from "../../i18n";

export default class NoMatch extends React.PureComponent {

  render() {
    return (
      <div className="container">
        <div className="row justify-content-center p-5 mt-5">
          <div className="col-md-4 text-center">

            {/* <Image src={process.env.PUBLIC_URL + "/assets/images/navigation/logo-silverman.svg"}
              alt="silverman" className="img-fluid mb-5" style={{ 'max-width': 300 }} /> */}
            <h4>ขออภัยไม่พบข้อมูลที่ต้องการ</h4>
            <p style={{ "font-size": 13 }}>อาจเป็นเพราะข้อมูลมีการอัพเดท ต้องขออภัยช่วงนี้เรามีการอัพเดทข้อมูลทุกวัน
              เพื่อให้คุณได้รับบริการที่ดีที่สุด คุณสามารถเข้าดูเว็บไซต์ใหม่ของได้เราได้โดยคลิกด้านล่าง</p>
            <Link to={(i18n.language !== "th" ? "/" + i18n.language : "") + "/"}>
              <button className="btn btn-dark pl-5 pr-5">
                เข้าสู่เว็บไซต์หลัก
              </button>
            </Link>

          </div>
        </div>
      </div>
    )
  }

}